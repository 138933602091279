import * as views from "./view";

export const switchContent = [
    {
        path: '/agents',
        exact: true,
        component: views.agentListView,
        hasMenu: false,
    },
    {
        path: '/agents/registration',
        exact: true,
        component: views.agentRegistrationView,
        hasMenu: false,
    },
    {
        path: '/:agentId/domain',
        exact: true,
        component: views.domainRouteView,
        hasMenu: true,
    },
    {
        path: '/:agentId/typos',
        exact: true,
        component: views.correctionKeywordView,
        hasMenu: true
    },
    {
        path: '/:agentId/domain/:domainId/skill-intent',
        exact: true,
        component: views.skillIntentRouteView,
        hasMenu: true,
    },
    // {
    //     path: '/:agentId/prebuilt',
    //     exact: true,
    //     component: views.prebuiltView,
    //     hasMenu: true,
    // },
    {
        path: '/:agentId/conversation',
        exact: true,
        component: views.conversationView,
        hasMenu: true,
    },
    {
        path: '/:agentId/settings',
        exact: true,
        component: views.agentSettingView,
        hasMenu: true,
    },
    {
        path: '/:agentId/dashboard',
        exact: true,
        component: views.dashboardView,
        hasMenu: true,
    },
    {
        path: '/:agentId/autocompletes',
        exact: true,
        component: views.autocompleteView,
        hasMenu: true,
    },
    {
        path: '/:agentId/autocompletes/:autocompleteId',
        exact: true,
        component: views.autocompleteDetailView,
        hasMenu: true,
    },
    {
        path: '/:agentId/synonyms',
        exact: true,
        component: views.synonymView,
        hasMenu: true,
    },
    {
        path: '/:agentId/selector',
        exact: true,
        component: views.selectorView,
        hasMenu: true,
    },
    {
        path: '/:agentId/sentence-cluster',
        exact: true,
        component: views.clusteringView,
        hasMenu: true,
    },
    {
        path: '/:agentId/entities',
        exact: true,
        component: views.entityNameView,
        hasMenu: true,
    },
    {
        path: '/:agentId/domain/:domainId/entities/:entityNameId',
        exact: true,
        component: views.entityView,
        hasMenu: true,
    },
    {
        path: '/:agentId/simulation/test',
        exact: true,
        component: views.bulkTestView,
        hasMenu: true,
    },
    {
        path: '/:agentId/simulation/result',
        exact: true,
        component: views.bulkTestReportView,
        hasMenu: true,
    },
    {
        path: '/:agentId/statistics/detail',
        exact: true,
        component: views.statisticsView,
        hasMenu: true,
    },
    {
        path: '/:agentId/members',
        exact: true,
        component: views.memberView,
        hasMenu: true,
    }
];