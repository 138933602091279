import React from "react";
import "mobx-react-lite/batchingForReactDom";
import { SkillStore } from "./SkillStore";
import { SlotTaggerStore } from "./SlotTaggerStore";
import { IntentStore } from "./IntentStore";
import { SlotModalStore } from "./SlotModalStore";
import { SentenceAnswerModalStore } from "./SentenceAnswerModalStore";
import { DomainStore } from "./DomainStore";
import { EntityNameStore } from "./EntityNameStore";
import { EntityStore } from "./EntityStore";
import { SynonymEditingStore } from "./SynonymEditingStore";
import { SelectorStore } from "./SelectorStore";
import AutocompleteStore from "./AutocompleteStore";
import AutocompleteTestStore from "./AutocompleteTestStore";
import SimulationStore from "./SimulationStore";
import AuthenticationStore from "./AuthenticationStore";
import AgentListStore from "./AgentListStore";
import AgentStore from "./AgentStore";
import AgentRegistrationStore from "./AgentRegistrationStore";
import SettingStore from "./SettingStore";
import CorrectionKeywordStore from "./CorrectionKeywordStore";
import StatisticsStore from "./StatisticsStore";
import LanguageSettingStore from "./LanguageSettingStore";
import AccountStore from "./AccountStore";
import ClusteringStore from "./ClusteringStore";
import RecentAgentsStore from "./RecentAgentsStore";
import SingleTestPanelStore from "./SingleTestPanelStore";
import IntentPredictStore from "./IntentPredictStore";
import DomainListStore from "./DomainListStore";
import SelectorSentenceListStore from "./SelectorSentenceListStore";
import IntentNameListStore from "./IntentNameListStore";
import SynonymListStore from "./SynonymListStore";
import EntityListStore from "./EntityListStore";
import EntityNameListStore from "./EntityNameListStore";
import EntityNameALLStore from "./EntityNameALLStore";
import DomainTempStore from "./DomainTempStore";
import EntityTempStore from "./EntityTempStore";
import DeployHistoryStore from "./DeployHistoryStore";
import WebSocketStore from "./WebSocketStore";
import ModalStore from "./ModalStore"
import MemeberStore from "./MemeberStore"

export const storesContext = React.createContext({
    agentListStore: new AgentListStore(),
    agentStore: new AgentStore(),
    agentRegistrationStore: new AgentRegistrationStore(),
    settingStore: new SettingStore(),
    skillStore: new SkillStore(),
    slotTaggerStore: new SlotTaggerStore(),
    intentStore: new IntentStore(),
    slotModalStore: new SlotModalStore(),
    sentenceAnswersModalStore: new SentenceAnswerModalStore(),
    domainStore: new DomainStore(),
    entityNameStore: new EntityNameStore(),
    entityListStore: new EntityListStore(),
    entityStore: new EntityStore(),
    selectorStore: new SelectorStore(),
    autocompleteStore: new AutocompleteStore(),
    autocompleteTestStore: new AutocompleteTestStore(),
    simulationStore: new SimulationStore(),
    authenticationStore: new AuthenticationStore(),
    correctionKeywordStore: new CorrectionKeywordStore(),
    statisticsStore: new StatisticsStore(),
    languageSettingStore: new LanguageSettingStore(),
    accountStore: new AccountStore(),
    clusteringStore: new ClusteringStore(),
    recentAgentsStore: new RecentAgentsStore(),
    singleTestPanelStore: new SingleTestPanelStore(),
    intentPredictStore: new IntentPredictStore(),
    domainListStore: new DomainListStore(),
    selectorSentenceListStore: new SelectorSentenceListStore(),
    intentNameListStore: new IntentNameListStore(),
    synonymListStore: new SynonymListStore(),
    synonymEditingStore: new SynonymEditingStore(),
    entityNameListStore: new EntityNameListStore(),
    entityNameALLStore: new EntityNameALLStore(),
    domainTempStore: new DomainTempStore(),
    entityTempStore: new EntityTempStore(),
    deployHistoryStore: new DeployHistoryStore(),
    webSocketStore: new WebSocketStore(),
    modalStore: new ModalStore(),
    memberStore: new MemeberStore()
});
