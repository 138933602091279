import {action, observable, runInAction} from "mobx";
import {Member, Role, Invitation} from "../model/Member";
import * as roleApi from "../apis/roleApi";
import * as memberApi from "../apis/memberApi";
import * as invitationApi from "../apis/invitationApi";

class MemberStore{
    @observable roles: Role[] = [];
    @observable members: Member[] = [];
    @observable invitations: Invitation[] = [];
    @observable userInvitations: Invitation[] = [];

    @action
    findRoles = async (scenarioId: string) => {
        try {
            const response = await roleApi.find(scenarioId);
            runInAction(() => {
                this.roles = response.data.data;
            })
        } catch (e) {
            return Promise.reject(e);
        }
    }

    @action
    findMembers = async (scenarioId: string) => {
        try {
            const response = await memberApi.findMembers(scenarioId);
            runInAction(() => {
                this.members = response.data.data;
            });
        } catch (e) {
            return Promise.reject(e);
        }
    }

    @action
    findInvitations = async (scenarioId: string) => {
        try {
            const response = await invitationApi.findInvitations(scenarioId);
            runInAction(() => {
                this.invitations = response.data.data;
            })
        } catch (e) {
            return Promise.reject(e);
        }
    }

    @action
    deleteInvitation = async (scenarioId: string, invitationId: string) => {
        try {
            await invitationApi.deleteInvitation(scenarioId, invitationId);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    @action
    findUserInvitations = async () => {
        try {
            const response = await invitationApi.findInvitationByUser();
            runInAction(() => {
                this.userInvitations = response.data.data;
            });
        } catch (e) {
            return Promise.reject(e);
        }
    }

    @action
    clearUserInvitations = () => {
        this.userInvitations = [];
    }

    @action
    clear = () => {
        this.members = [];
        this.roles = [];
        this.invitations = [];
        this.userInvitations = [];
    }
}

export default MemberStore;