import axios from "axios";
import {API_HOST} from "../variables";

export const createInvitation = async (agentId: string, username: string, roleId?: string, managementUnitRoleId?: string) => {
    return axios.post(`${API_HOST}/v1/agents/${agentId}/invitations`, {
            username: username,
            role_id: roleId,
            management_unit_role_id: managementUnitRoleId
        }
    );
}

export const findInvitations = async (agentId: string) => {
    return axios.get(`${API_HOST}/v1/agents/${agentId}/invitations`);
}

export const findInvitationByUser = async () => {
    return axios.get(`${API_HOST}/v1/invitations`);
}

export const deleteInvitation = async (agentId: string, invitationId: string) => {
    return axios.delete(`${API_HOST}/v1/agents/${agentId}/invitations/${invitationId}`);
}

export const applyInvitation = async (invitationId: string) => {
    return axios.post(`${API_HOST}/v1/invitations/${invitationId}`);
}