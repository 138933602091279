import axios from "axios";
import {API_HOST} from "../variables";

export const findMembers = async (agentId: string) => {
    return await axios.get(`${API_HOST}/v1/agents/${agentId}/members`);
};

export const updateMember = async (agentId: string, memberId: string, isDefault: boolean, roleId: string) => {
    return axios
        .put(`${API_HOST}/v1/agents/${agentId}/members/${memberId}`, {
                default: isDefault,
                role_id: roleId
            }
        );
}

export const deleteMember = async (agentId: string, memberId: string)=> {
    return axios.delete(`${API_HOST}/v1/agents/${agentId}/members/${memberId}`);
}