import {action, observable} from "mobx";

class ModalStore {
    @observable status: {[id: string]: boolean} = {};
    @observable data: {[id: string]: any} = {};

    @action
    open = (id: string, data?: any) => {
        this.status = {
            ...this.status,
            [id]: true
        };
        if (!data)
            return

        this.data = {
            ...this.data,
            [id]: data
        };
    }

    @action
    close = (id: string) => {
        this.status = {
            ...this.status,
            [id]: false
        };
    }

    @action
    clear = () => {
        this.status = {};
        this.data = {};
    }
}

export default ModalStore;